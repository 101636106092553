import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from './pages/login/Login';
import Payoff from './components/Payoff';
import Published from './pages/published/Published';
import Pipeline from './pages/pipeline/Pipeline';
import Eligibility from './pages/eligibility/Eligibility';
import Messages from './pages/messages/Messages';
import Funders from './pages/funders/Funders';
import Institutions from './pages/institutions/Institutions';
import { userSettings } from './user-settings';
import {
    Navbar,
    token,
    findFirstItem
} from 'pubtrack-frontend-library';


const PrivateRoute = ({ component: Component, ...rest }) => {
    const authorized = localStorage.getItem(token);

    return (
        <Route {...rest}
            render={props => authorized
                ?
                <>
                    <Payoff />
                    <Navbar navItems={userSettings.navbar} {...props} />
                    <Component {...props} />
                </>
                :
                <Redirect to="/login" />
            } />
    );
};


const Routes = () => {
    return (
        <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/pipeline" component={Pipeline} />
            <PrivateRoute path="/eligibility" component={Eligibility} />
            <PrivateRoute path="/published" component={Published} />
            <PrivateRoute path="/messages" component={Messages} />
            <PrivateRoute path="/funders" component={Funders} />
            <PrivateRoute path="/institutions" component={Institutions} />
            <Redirect from="/" to={findFirstItem(userSettings.navbar, "path")} />
        </Switch>
    );
};


export default Routes;