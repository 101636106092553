export const changeFormatDate = date => {
    return date
        ? [date.getFullYear(), date.getMonth() + 1, date.getDate()].map((date) => (date < 10 ? `0${date}` : date)).join('-')
        : '';
};

export const transformDate = date => {
    let res = null;
    if (date) {
        const created = new Date(date);
        res = `${changeFormatDate(created)}`;
    }
    return res;
};

export const transformData = data => {
    return { article: { ...data } };
};