export const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
export const OASB_URL = `${process.env.REACT_APP_API_AUTH_URL}`;
export const publisher_id = `${process.env.REACT_APP_PUBLISHER_ID}`;

export const itemsPerPage = 10;

export const allFilterOption = {
    "issn": "",
    "title": "All journals",
    "id": "All journals"
};
