// Here we can turn on/off items which are displayed at navbar and modal tabs by changing 'display' key

export const userSettings = {
    navbar: [
        {
            display: true,
            label: "In pipeline",
            path: "/pipeline"
        },
        {
            display: true,
            label: "Eligibility",
            path: "/eligibility"
        },
        {
            display: true,
            label: "Published articles",
            path: "/published"
        },
        {
            display: true,
            label: "Messages",
            path: "/messages"
        },
        {
            display: true,
            label: "Institutions",
            path: "/Institutions"
        },
        {
            display: true,
            label: "Funders",
            path: "/funders"
        }
    ],
    details: [
        {
            display: true,
            label: "Metadata",
        },
        {
            display: true,
            label: "JSON",
        },
        {
            display: true,
            label: "Events",
        },
        {
            display: true,
            label: "Messages",
        },
        {
            display: false,
            label: "Eligibility",
        },
        {
            display: false,
            label: "Affiliations",
        },
    ],
    messagesDetails: [
        {
            display: false,
            label: "Metadata",
        },
        {
            display: true,
            label: "JSON",
        },
    ],
};