import { transformDate } from "../../helpers";

export const funderMessagesColumns = [
    {
        Header: 'Message',
        accessor: 'id',
        sortable: true
    },
    {
        Header: 'Created',
        Cell: ({ row }) =>
            <span>
                {transformDate(row.original.created)}
            </span>,
        sortable: true
    },
    {
        Header: 'Type',
        accessor: 'type',
        sortable: true,
    },
    {
        Header: 'Sent to',
        accessor: 'sentto',
        sortable: true
    },
    {
        Header: 'Journal',
        accessor: 'journal',
        sortable: true
    },
    {
        Header: 'Manuscript',
        accessor: 'manuscript',
        sortable: true
    },
    {
        Header: 'DOI',
        accessor: 'doi',
        sortable: true
    },
    {
        Header: 'State',
        accessor: 'state',
        sortable: true
    },
]

export const publishedArticlesColumns = [
    {
        Header: 'DOI',
        accessor: 'doi',
    },
    {
        Header: 'Title',
        accessor: 'title',
    },
    {
        Header: 'Journal',
        accessor: 'journal'
    }
]

export const inPipeLineArticlesColumns = [
    {
        Header: 'Manuscript ID',
        accessor: 'manuscript_id',
        sortable: false
    },
    {
        Header: 'Title',
        accessor: 'title',
        sortable: false
    },
    {
        Header: 'Journal',
        accessor: 'journal',
        sortable: false
    },
]

export const articlesPerInstitutionColumns = [
    {
        Header: 'Funder',
        accessor: 'institution',
    },
    {
        Header: 'Count',
        accessor: 'count',
    },
];